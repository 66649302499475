.hello-container{
    margin: 150px 20px;
}
.hello-container .hello{
    max-width: 800px;
    margin: auto;
    padding: 40px 20px 60px;
    border-radius: 20px;
    text-align: center;
    border: 5px dashed #141c3a;
}
.hello .title{
    font-size: 2em;
    line-height: 1.4;
    margin-bottom: 30px;
}
.hello .desc {
    font-size: 1.2em;
    margin-bottom: 50px;
}
.hello .button{
    background-color: #141c3a;
    border: 3px solid #141c3a;
}
.hello .button:hover{
    color: #141c3a;
    background-color: #fff;
}