.form-submitted{
    max-width: 600px;
    margin: auto;
    text-align: center;
}
.form-submitted .icon{
    color: #6b6bc3;
}
.form-submitted .title{
    font-size: 2.5em;
}
.form-submitted .sub{
    font-size: 1.2em;
    margin: 20px 0 50px;
}
.form-submitted .button{
    color: #6b6bc3;
    background-color: #fff;
    border: 2px solid #6b6bc3;
}
.form-submitted .button:hover{
    color: #fff;
    background-color: #6b6bc3;
}
.contact-form-container{
    margin: 50px 20px 200px;
}
.contact-form{
    width: 100%;
    max-width: 800px;
    margin: auto;
}
.contact-form form {
    max-width: 600px;
    margin: auto;
}
.contact-form h1 {
    font-weight: normal;
    text-align: center;
    margin-bottom: 50px;
}
@media screen and (min-width: 512px) {
    .contact-form .double {
        display: flex;
        justify-content: center;
        gap: 20px;
    }
}
.contact-form button {
    margin: auto;
    display: block;
    padding: 15px 50px;
    margin-top: 30px;
    border-radius: 50px;
}