.footer-container{
    background-color: #6b6bc3;
    padding: 100px 20px;
    color: #fff;
}
.footer{
    width: 100%;
    max-width: 1024px;
    margin: auto;
    text-align: center;
}
.footer .slogan{
    font-size: 1.5em;
    margin: 40px 0 50px;
}
.footer .social{
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 50px;
}
.footer .social a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
.footer .social a:hover {
    background-color: #fff;
    color: #6b6bc3;
}
.footer .social .icon {
    font-size: 1.5em;
}
.footer .copyright{
    color: #d1d1d1;
}