.projects-container{
    margin: 80px 20px;
}
.projects{
    max-width: 100%;
    max-width: 1024px;
    margin: auto;
    text-align: center;
}
.project-heading .title{
    font-size: 2em;
    font-weight: 500;
}
.project-heading .desc{
    font-size: 1.2em;
    margin: 10px 0 60px;
}
.project-list{
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    justify-content: center;
}
.project-list figure{
    margin: 0;
    padding: 0 0 10px;
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    overflow: hidden;
    width: 322px;
}
.project-list .image{
    position: relative;
    background-color: #141c3a;
}
.project-list img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
}
.project-list .image:hover img{
    position: relative;
    opacity: .3;
}
.project-list .img-overlay{
    color: #fff;
    font-weight: 500;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    opacity: 0;
}
.project-list .image:hover .img-overlay{
    opacity: 1;
}
.project-list .title{
    font-size: 1.1em;
    font-weight: 500;
    margin: 10px;
    display: block;
}
.project-list figcaption{
    margin: 10px;
}
